import React, { useState, useEffect } from 'react';
import './styles/main.css';
import neuralNetworkImage from './images/neural-network.webp'; // Make sure this path is correct in your project structure

function App() {
  const [dataPoints, setDataPoints] = useState(0);
  const [processingPower, setProcessingPower] = useState(0);
  const [algorithms, setAlgorithms] = useState(0);
  const [agiStage, setAgiStage] = useState(1);
  const [dataPointsPerClick, setDataPointsPerClick] = useState(1);
  const [dataPointsPerSecond, setDataPointsPerSecond] = useState(0);
  const version = "0.1.0";
  
  const generateDataPoint = () => {
    setDataPoints(prev => prev + dataPointsPerClick);
  };

  const buyProcessingPower = () => {
    const cost = processingPowerCost(processingPower);
    if (dataPoints >= cost) {
      setProcessingPower(prev => prev + 1);
      setDataPoints(prev => prev - cost);
      setDataPointsPerSecond(prev => prev + 1);
    }
  };

  const buyAlgorithm = () => {
    const cost = algorithmCost(algorithms);
    if (dataPoints >= cost) {
      setAlgorithms(prev => prev + 1);
      setDataPoints(prev => prev - cost);
      setDataPointsPerClick(prev => prev + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDataPoints(prevDataPoints => prevDataPoints + dataPointsPerSecond);
    }, 1000);
    return () => clearInterval(interval);
  }, [dataPointsPerSecond]);

  useEffect(() => {
    if (dataPoints >= 1000 * agiStage) {
      setAgiStage(prevStage => prevStage + 1);
      setDataPointsPerClick(prev => prev * 2); // Double the data points per click with each AGI stage
    }
  }, [dataPoints, agiStage]);

  // Helper functions to calculate costs
  const processingPowerCost = (currentPower) => {
    return Math.floor(10 * Math.pow(1.1, currentPower));
  };

  const algorithmCost = (currentAlgorithms) => {
    return Math.floor(20 * Math.pow(1.2, currentAlgorithms));
  };

  return (
    <div className="app">
      <header>
        <h1>AGI Constructor</h1>
      </header>
      <main>
        <section className="dashboard">
          <div className="stats">
            <p>Data Points: {dataPoints}</p>
            <p>Processing Power: {processingPower} (Generates {dataPointsPerSecond} per second)</p>
            <p>Algorithms: {algorithms} (Adds {dataPointsPerClick - 1} per click)</p>
            <p>AGI Stage: {agiStage}</p>
          </div>
          <div className="actions">
            <button onClick={generateDataPoint}>Generate Data Point</button>
            <button onClick={buyProcessingPower} disabled={dataPoints < processingPowerCost(processingPower)}>Buy Processing Power</button>
            <button onClick={buyAlgorithm} disabled={dataPoints < algorithmCost(algorithms)}>Buy Algorithm</button>
          </div>
        </section>
      </main>
      <footer>
        <p>Build the AGI. Advance the Future.</p>
        <p className="version">Version: {version}</p> {/* Display the version here */}
      </footer>
      <section className="progress-display">
          <img src={neuralNetworkImage} alt="AGI Neural Network Progress" className="neural-network-image"/>
        </section>
    </div>
  );
}

export default App;
